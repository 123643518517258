/**
 * Originally intended to be constants with details about each election.
 * Instead became an awful mess containing all election-specific configuration.
 * Needs major work.
 */

/* eslint-disable  */
import React, { Fragment } from 'react';
import ACT24WVG from '~/components/sections/WhyVoteGreen/ACT24WVG/ACT24WVG';
import BCC24WVG from '~/components/sections/WhyVoteGreen/BCC24WVG/BCC24WYG';
import Fed19WVG from '~/components/sections/WhyVoteGreen/Fed19WVG/Fed19WVG';
import Fed22WVG from '~/components/sections/WhyVoteGreen/Fed22WVG/Fed22WVG';
import Nsw23WVG from '~/components/sections/WhyVoteGreen/Nsw23WVG/Nsw23WVG';
import QLD24WVG from '~/components/sections/WhyVoteGreen/QLD24WVG/QLD24WVG';
import TAS24WVG from '~/components/sections/WhyVoteGreen/TAS24WVG/TAS24WYG';
import Vic22WVG from '~/components/sections/WhyVoteGreen/Vic22WVG/Vic22WVG';
import Wa21WVG from '~/components/sections/WhyVoteGreen/Wa21WVG/Wa21WVG';
import Wa25WVG from '~/components/sections/WhyVoteGreen/Wa25WVG/Wa25WVG';
import {
    ACT24Ballot, BCC24Ballot, FED19Ballot, FED22Ballot, NSW19Ballot, NSW23Ballot, QLD20Ballot,
    QLD20SBallot, QLD24Ballot, SA22Ballot, TAS24Ballot, VIC18Ballot, VIC22Ballot, WA21Ballot,
    WA25Ballot
} from '~/helpers/constants/BallotDesigns';
import { FEDERAL, LOCAL, STATE } from '~/helpers/constants/ElectionTypes';
import {
    ACTStateDistricts, FederalDistricts, FederalDistrictsByState, FederalStates, NswStateDistricts,
    NTStateDistricts, QueenslandCouncilWards, QueenslandCouncilWardsToExtraBallot,
    QueenslandExtraBallots, QueenslandStateDivisions, SaStateDistricts, TasmaniaStateDistricts,
    VictorianStateDistricts, VictorianStateRegions, VictorianStateRegionsByDistrict,
    WaStateDistricts, WaStateRegions, WaStateRegionsByDistrict
} from '~/helpers/constants/Electorates';
import { DISTRICT, REGION, STATE as StateElectorate } from '~/helpers/constants/ElectorateTypes';
import { ACT, NSW, NT, QLD, SA, TAS, VIC, WA } from '~/helpers/constants/States';
import { Config } from '~/helpers/types/Config';

export const VIC18: Config = {
  election: {
    code: 'vic18',
    name: '2018 Victorian State Election',
    type: STATE,
    state: VIC,
    electionDay: new Date('2018-11-24'),
    pollsAreClosed: true,
  },
  ballot: VIC18Ballot,
  partyNameOnBallot: ['AUSTRALIAN GREENS'],
  getCandidateInfo: true,
  electorates: {
    lower: VictorianStateDistricts,
    upper: VictorianStateRegions,
    upperType: REGION,
    lowerToUpper: lower => VictorianStateRegionsByDistrict[lower]
  },
  defaultLocation: {
    lat: -37.8136,
    lng: 144.9631
  },
  authorisationLine: 'Authorised by C. Quinn, 1/45 William St, Melbourne VIC 3000',
  // whyVoteGreenContent: <Issues issues={ElectionIssues.vic18} />,
};

export const NSW19: Config = {
  election: {
    code: 'nsw19',
    name: '2019 New South Wales State Election',
    type: STATE,
    state: NSW,
    electionDay: new Date('2019-03-23'),
    pollsAreClosed: true,
  },
  ballot: NSW19Ballot,
  partyNameOnBallot: ['THE GREENS'],
  showGroupLabelOnBallot: true,
  getCandidateInfo: false,
  electorates: {
    lower: NswStateDistricts,
    upper: ['NSW'],
    upperType: StateElectorate,
    lowerToUpper: (lower) => 'NSW'
  },
  defaultLocation: {
    lat: -33.8688,
    lng: 151.2093
  },
  authorisationLine: 'Authorised by A Blake, The Greens NSW, Suite D, Level 1, 275 Broadway Glebe NSW 2037',
  // whyVoteGreenContent: <PopGrid tiles={ElectionIssues.nsw19} rowSize={3} />,
};

export const FED19: Config = {
  election: {
    code: 'nsw19',
    name: '2019 New South Wales State Election',
    type: STATE,
    state: NSW,
    electionDay: new Date('2019-05-18'),
    pollsAreClosed: true,
  },
  ballot: FED19Ballot,
  prodDomain: 'vote.greens.org.au',
  partyNameOnBallot: ['The Greens', 'The Greens (VIC)', 'The Greens (WA)'],
  upperBallotLogoPosition: 'above',
  getCandidateInfo: false,
  showUpperFirst: true,
  headerTitle: 'Vote 1 for Climate Action',
  showGroupLabelOnBallot: false,
  capitaliseUpperHouseParties: true,
  linkCandidateOnBallot: true,
  electorates: {
    lower: FederalDistricts,
    upper: FederalStates,
    upperType: StateElectorate,
    lowerToUpper: lower => FederalDistrictsByState[lower]
  },
  groupElectoratesOnSelect: true,
  boldGreensCandidate: true,
  defaultLocation: {
    lat: -25.3636,
    lng: 134.2117
  },
  authorisationLine: 'Authorised by Willisa Hogarth and Catherine Garner, The Greens, 2/97 Northbourne Avenue Turner ACT',
  whyVoteGreenContent: <Fed19WVG />,
  easyEnglishGuideUrl: new URL('https://contattafiles.s3.us-west-1.amazonaws.com/tnt34836/L92VHqYk5em5jwK/How%20to%20vote%20Greens_Easy%20English_web%20%5BAccessible%5D.pdf'),
  shareUrl: new URL('https://vote.greens.org.au'),
  shareText: "Together, we're building a future for all of us. Find out how to vote Greens in the upcoming federal election, and more about your local candidate.",
  footerLinks: [
    { title: 'Privacy', url: new URL('https://greens.org.au/privacy') },
    { title: 'Main website', url: new URL('https://greens.org.au') },
    { title: 'Contact us', url: new URL('https://greens.org.au/contact') }
  ],
  showOverseasInfo: true
};

export const QLD20: Config = {
  election: {
    code: 'qld20',
    name: '2020 Queensland Local Elections',
    electionDay: new Date('2020-03-28'),
    pollsAreClosed: true,
    type: STATE,
  },
  ballot: QLD20Ballot,
  prodDomain: 'vote.greens.org.au',
  partyNameOnBallot: ['The Greens'],
  getCandidateInfo: false,
  showUpperFirst: true,
  headerTitle: 'A Future For All Of Us',
  showGroupLabelOnBallot: false,
  linkCandidateOnBallot: false,
  electorates: {
    lower: QueenslandCouncilWards,
    upper: QueenslandExtraBallots,
    lowerToUpper: lower => QueenslandCouncilWardsToExtraBallot[lower]
  },
  boldGreensCandidate: true,
  defaultLocation: {
    lat: -25.3636,
    lng: 134.2117
  },
  authorisationLine: 'Authorised by K. Carra, Queensland Greens, 102 McDonald Road, Windsor 4030',
  whyVoteGreenContent: (
    <div>
      <p>
        It’s an important time in Queensland. We have multiple elections happening on 28 March: the Council elections and two by-elections in Bundamba and Currumbin.
        Early voting has started and many Queenslanders have already made their voice heard.{' '}
      </p>
      <p>At the moment it seems like politics is completely disconnected from the things we actually care about. We can change this.</p>
      <p>This is your chance to have your say, to tell your representatives what you want for the place where you live. </p>
      <p>We believe everyone deserves equal opportunities and the right to a good life.</p>
      <p>
        Our candidates and members have been out in the community, listening to the concerns of local residents. We have heard the call for cities and towns that are
        more affordable, more accessible and greener.
      </p>
      <p>
        Right now, our cities and towns are being shaped by politicians who receive donations from big corporates and rich developers. That has led to
        over-development, high prices, ineffective waste management systems, traffic chaos, unsafe roads, expensive, unreliable public transport and a lack of green
        space.
      </p>
      <p>We do not take donations from big corporates and rich developers like the other parties. That means we govern for you – not donors and vested interests.</p>
      <p>We want decisions about planning and the future of our cities and towns to be put back into the hands of the people who live there – you.</p>
      <p>
        A vote for the Greens is a vote for:
        <ul>
          <li>affordable housing</li>
          <li>cheap, accessible public transport</li>
          <li>safer roads and more crossings</li>
          <li>ways to help shops and small businesses thrive</li>
          <li>limits on development</li>
          <li>effective ways to recycle and manage waste</li>
          <li>action on the climate emergency</li>
          <li>more green space</li>
        </ul>
      </p>
      <p>Together, we can make it happen. These elections, vote 1 Greens. </p>
    </div>
  ),
  shareUrl: new URL('https://vote.greens.org.au'),
  shareText: 'With more Greens on Council, we can make sure our communities and your needs come first.',
  footerLinks: [
    { title: 'Privacy', url: new URL('https://greens.org.au/privacy') },
    { title: 'Main website', url: new URL('https://greens.org.au/qld') }
  ],
  electionHasSausage: false
};

export const QLD20S: Config = {
  election: {
    code: 'qld20s',
    name: '2020 Queensland State Election',
    electionDay: new Date('2020-10-31'),
    pollsAreClosed: true,
    type: STATE,
  },
  ballot: QLD20SBallot,
  pollsAreClosed: true,
  prodDomain: 'vote.greens.org.au',
  partyNameOnBallot: ['Queensland Greens'],
  getCandidateInfo: true,
  showUpperFirst: false,
  headerTitle: 'How To Vote Greens...',
  showGroupLabelOnBallot: false,
  linkCandidateOnBallot: false,
  electorates: {
    lower: QueenslandStateDivisions,
  },
  boldGreensCandidate: true,
  defaultLocation: {
    lat: -25.3636,
    lng: 134.2117
  },
  authorisationLine: 'Authorised by K. Carra, Queensland Greens, 102 McDonald Road, Windsor 4030',
  shareUrl: new URL('https://vote.greens.org.au'),
  shareText: 'Vote [1] Greens for a future for all of us.',
  footerLinks: [
    { title: 'Privacy', url: new URL('https://greens.org.au/privacy') },
    { title: 'Main website', url: new URL('https://greens.org.au/qld') }
  ],
  electionHasSausage: true,
  hideHowToVoteInstructions: true,
};

export const WA21: Config = {
  election: {
    code: 'wa21',
    name: '2021 WA State Election',
    electionDay: new Date('2021-03-13'),
    pollsAreClosed: true,
    type: STATE,
    state: WA,
  },
  ballot: WA21Ballot,
  pollsAreClosed: false,
  prodDomain: 'vote.greens.org.au',
  partyNameOnBallot: ['THE GREENS (WA)'],
  getCandidateInfo: false,
  showUpperFirst: true,
  headerTitle: 'Vote Greens for Climate Action',
  showGroupLabelOnBallot: false,
  linkCandidateOnBallot: false,
  electorates: {
    lower: WaStateDistricts,
    upper: WaStateRegions,
    upperType: REGION,
    lowerToUpper: lower => WaStateRegionsByDistrict[lower],
  },
  boldGreensCandidate: true,
  defaultLocation: {
    lat: -24.1448,
    lng: 111.8624
  },
  authorisationLine: 'Authorised by Emma Pringle & Gareth Doogue, The Greens (WA), 91/215 Stirling St, Perth 6000',
  whyVoteGreenContent: <Wa21WVG />,
  easyEnglishGuideUrl: new URL('https://greens.org.au/sites/default/files/2021-02/wa2021-htv-greens-easyenglish.pdf'),
  shareUrl: new URL('https://vote.greens.org.au'),
  shareText: 'Vote [1] Greens for a future for all of us.',
  footerLinks: [
    { title: 'Privacy', url: new URL('https://greens.org.au/privacy') },
    { title: 'Main website', url: new URL('https://greens.org.au/wa') }
  ],
  electionHasSausage: true,
  hideHowToVoteInstructions: false,
};

export const SA22: Config = {
  election: {
    code: "sa22",
    name: "2022 SA State Election",
    electionDay: new Date("2022-03-19"),
    type: STATE,
    state: SA,
    pollsAreClosed: false, // not actually used but required for type compliance
  },
  ballot: SA22Ballot,
  pollsAreClosed: false,
  prodDomain: "vote.greens.org.au",
  partyNameOnBallot: ["The Greens"],
  getCandidateInfo: false,
  showUpperFirst: true,
  headerTitle: "Vote Greens for Climate Action",
  showGroupLabelOnBallot: false,
  linkCandidateOnBallot: false,
  electorates: {
    lower: SaStateDistricts,
    upper: ["SA"],
    upperType: StateElectorate,
    lowerToUpper: (lower) => "SA",
  },
  boldGreensCandidate: true,
  defaultLocation: {
    lat: -34.9979,
    lng: 138.5122,
  },
  authorisationLine:
    "Authorised by I. Green, The Greens, 7/291 Angas Street, Adelaide SA 5000",
  // whyVoteGreenContent: <Sa22WVG />,
  shareUrl: new URL("https://vote.greens.org.au"),
  shareText: "Vote [1] Greens for climate action.",
  footerLinks: [
    { title: "Privacy", url: new URL("https://greens.org.au/privacy") },
    { title: "Main website", url: new URL("https://greens.org.au/sa") },
  ],
  electionHasSausage: false,
  hideHowToVoteInstructions: true,
};

export const FED22: Config = {
  election: {
    code: 'fed22',
    name: '2022 Federal Election',
    type: FEDERAL,
    electionDay: new Date('2022-05-21'),
    pollsAreClosed: true,
  },
  ballot: FED22Ballot,
  electionHasSausage: true,
  pollsAreClosed: true,
  prodDomain: 'vote.greens.org.au',
  partyNameOnBallot: ['Australian Greens', 'The Greens NSW', 'Queensland Greens', 'The Australian Greens - Victoria', 'The Greens (WA) Inc', 'The Greens (WA)', 'The Greens'],
  upperBallotLogoPosition: 'above',
  getCandidateInfo: true,
  getCandidateInfoUpper: true,
  showUpperFirst: true,
  headerTitle: 'Vote Climate, Vote 1 Greens',
  showGroupLabelOnBallot: false,
  capitaliseUpperHouseParties: true,
  linkCandidateOnBallot: true,
  electorates: {
    lower: FederalDistricts,
    upper: FederalStates,
    lowerType: DISTRICT,
    upperType: StateElectorate,
    lowerToUpper: lower => FederalDistrictsByState[lower]
  },
  groupElectoratesOnSelect: true,
  boldGreensCandidate: true,
  defaultLocation: {
    lat: -25.3636,
    lng: 134.2117
  },
  authorisationLine: 'Authorised by Matthew Roberts and Willisa Osburn, The Greens, 2/18 Lonsdale Street Braddon ACT 2612',
  whyVoteGreenContent: <Fed22WVG />,
  easyEnglishGuideUrl: new URL('https://greens.org.au/easy-to-read'),
  shareUrl: new URL('https://vote.greens.org.au'),
  shareText: "Hey, here's how to vote Greens this election. Your vote can help kick the Libs out and give the Greens the power to get real action on the climate crisis and dental and mental health into Medicare 💚",
  footerLinks: [
    { title: 'Translations', url: new URL('https://greens.org.au/in-language') },
    { title: 'Privacy', url: new URL('https://greens.org.au/privacy') },
    { title: 'Main site', url: new URL('https://greens.org.au') },
  ],
  showOverseasInfo: true,
  candidateUrlOverrides: {
    'VIC': 'lidia-thorpe',
    'QLD': 'penny-allman-payne',
    'TAS': 'peter-whish-wilson',
    'WA': 'dorinda-cox',
    'ACT': 'dr-tjanara-goreng-goreng',
    'NT': 'jane-anlezark',
    'SA': 'barbara-pocock',
    'NSW': 'david-shoebridge'
  },
  upperBallotOverrides: {
    "Banks": "NSW-ALT",
    "Bennelong": "NSW-ALT",
    "Berowra": "NSW-ALT",
    "Bradfield": "NSW-ALT",
    "Calare": "NSW-ALT",
    "Cook": "NSW-ALT",
    "Cowper": "NSW-ALT",
    "Dobell": "NSW-ALT",
    "Farrer": "NSW-ALT",
    "Hughes": "NSW-ALT",
    "Hume": "NSW-ALT",
    "Lindsay": "NSW-ALT",
    "Lyne": "NSW-ALT",
    "Mackellar": "NSW-ALT",
    "Mitchell": "NSW-ALT",
    "New England": "NSW-ALT",
    "North Sydney": "NSW-ALT",
    "Page": "NSW-ALT",
    "Parkes": "NSW-ALT",
    "Richmond": "NSW-ALT",
    "Riverina": "NSW-ALT",
    "Robertson": "NSW-ALT",
    "Warringah": "NSW-ALT",
    "Wentworth": "NSW-ALT",
  }
};
export const VIC22: Config = {
  election: {
    code: 'vic22',
    name: '2022 Victorian State Election',
    type: STATE,
    state: VIC,
    electionDay: new Date('2022-11-26'),
    pollsAreClosed: true,
  },
  ballot: VIC22Ballot,
  electionHasSausage: true,
  pollsAreClosed: true,
  prodDomain: 'vote.greens.org.au',
  partyNameOnBallot: ['AUSTRALIAN GREENS'],
  boldGreensCandidate: true,
  showGroupLabelOnBallot: false,
  getCandidateInfo: true,
  electorates: {
    lower: VictorianStateDistricts,
    lowerType: DISTRICT,
    upper: VictorianStateRegions,
    upperType: REGION,
    lowerToUpper: lower => VictorianStateRegionsByDistrict[lower]
  },
  defaultLocation: {
    lat: -37.8136,
    lng: 144.9631
  },
  authorisationLine: 'Authorised by M. Shield, 1/45 William St, Melbourne VIC 3000',
  whyVoteGreenContent: <Vic22WVG />,
  shareUrl: new URL('https://vote.greens.org.au'),
  shareText: "Hey, here's how to vote Greens this election.",
  footerLinks: [
    { title: 'Main Site', url: new URL('https://greens.org.au/vic') },
    { title: 'Privacy', url: new URL('https://greens.org.au/privacy') },
    { title: 'Contact us', url: new URL('https://greens.org.au/vic/contact') },
  ],
  showOverseasInfo: false,
  getCandidateInfoUpper: true,
  candidateUrlOverrides: {
    'Southern Metropolitan': 'katherine-copsey',
    'Northern Metropolitan': 'samantha-ratnam',
    'South Eastern Metropolitan': 'alex-breskin',
    'North Eastern Metropolitan': 'aiv-puglielli',
    'Western Metropolitan': 'bernadette-thomas',
    'Western Victoria': 'sarah-mansfield',
    'Northern Victoria': 'dr-cate-sinclair',
    'Eastern Victoria': 'mat-morgan'
  },
};

export const NSW23: Config = {
  election: {
    code: 'nsw23',
    name: '2023 New South Wales State Election',
    type: STATE,
    state: NSW,
    electionDay: new Date('2023-03-25'),
    pollsAreClosed: false,
  },
  ballot: NSW23Ballot,
  // ballot: NewNSW23Ballot,
  prodDomain: 'vote.greens.org.au',
  electionHasSausage: true,
  pollsAreClosed: false,
  partyNameOnBallot: ['THE GREENS'],
  showGroupLabelOnBallot: true,
  getCandidateInfo: true,
  getCandidateInfoUpper: true,
  candidateUrlOverrides: {
    'NSW': 'cate-faerhmann-1'
  },
  electorates: {
    lower: NswStateDistricts,
    lowerType: DISTRICT,
    upper: ['NSW'],
    upperType: StateElectorate,
    lowerToUpper: (lower) => 'NSW'
  },
  defaultLocation: {
    lat: -33.8688,
    lng: 151.2093
  },
  authorisationLine: 'Authorised by A Blake, The Greens NSW, 19A/1 Hordern Place Camperdown NSW 2050',
  whyVoteGreenContent: <Nsw23WVG />,
  shareUrl: new URL('https://vote.greens.org.au'),
  shareText: "Hey, here's how to vote Greens this election.",
  footerLinks: [
    { title: 'Main Site', url: new URL('https://greens.org.au/nsw') },
    { title: 'Privacy', url: new URL('https://greens.org.au/privacy') },
  ],
  showOverseasInfo: true
};

export const BCC24: Config = {
  election: {
    code: 'bcc24',
    name: '2024 Queensland Local Government Elections',
    type: LOCAL,
    state: QLD,
    electionDay: new Date('2024-03-16'),
    pollsAreClosed: false,
  },
  ballot: BCC24Ballot,
  prodDomain: 'vote.greens.org.au',
  electionHasSausage: false,
  pollsAreClosed: false,
  partyNameOnBallot: ['The Greens'],
  showGroupLabelOnBallot: true,
  getCandidateInfo: true,
  getCandidateInfoUpper: true,
  electorates: {
    lower: QueenslandCouncilWards,
    lowerType: DISTRICT,
    upper: ['Brisbane City'],
    lowerToUpper: (lower) => QueenslandCouncilWardsToExtraBallot[lower]
  },
  defaultLocation: {
    lat: -27.46885,
    lng: 153.023602
  },
  authorisationLine: 'Authorised by K. Carra, Queensland Greens, 47 Park Road, Milton 4064',
  whyVoteGreenContent: <BCC24WVG />,
  shareUrl: new URL('https://vote.greens.org.au'),
  shareText: "Hey, here's how to vote Greens this election.",
  footerLinks: [
    { title: 'Main Site', url: new URL('https://greens.org.au/qld') },
    { title: 'Privacy', url: new URL('https://greens.org.au/privacy') },
  ],
  showOverseasInfo: false,
};

export const TAS24: Config = {
  election: {
    code: 'tas24',
    name: '2024 Tasmanian State Election',
    type: STATE,
    state: TAS,
    electionDay: new Date('2024-03-23'),
    pollsAreClosed: false,
  },
  ballot: TAS24Ballot,
  prodDomain: 'vote.greens.org.au',
  electionHasSausage: false,
  pollsAreClosed: false,
  partyNameOnBallot: ['The Greens'],
  showGroupLabelOnBallot: true,
  getCandidateInfo: true,
  getCandidateInfoUpper: true,
  electorates: {
    lower: TasmaniaStateDistricts,
    lowerType: DISTRICT,
    upper: [''],
    lowerToUpper: (lower) => QueenslandCouncilWardsToExtraBallot[lower]
  },
  defaultLocation: {
    lat: -42.8826,
    lng: 147.3257
  },
  authorisationLine: 'Authorised by D. Irving, Tasmanian Greens, 298 Argyle Street, North Hobart',
  whyVoteGreenContent: <TAS24WVG />,
  shareUrl: new URL('https://vote.greens.org.au'),
  shareText: "Hey, here's how to vote Greens this election.",
  footerLinks: [
    { title: 'Main Site', url: new URL('https://greens.org.au/tas') },
    { title: 'Privacy', url: new URL('https://greens.org.au/privacy') },
  ],
  showOverseasInfo: false,
};

export const NT24: Config = {
  election: {
    code: 'nt24', //TODO update
    name: '2024 Northern Territory Election',
    type: STATE,
    state: NT,
    electionDay: new Date('2024-08-24'),
    pollsAreClosed: false,
  },
  ballot: ACT24Ballot,
  prodDomain: 'vote.greens.org.au',
  electionHasSausage: false,
  pollsAreClosed: false,
  partyNameOnBallot: ['The Greens', 'THE GREENS'],
  showGroupLabelOnBallot: true,
  getCandidateInfo: true,
  getCandidateInfoUpper: true,
  electorates: {
    lower: NTStateDistricts,
    lowerType: DISTRICT,
    upper: [''],
    lowerToUpper: (lower) => QueenslandCouncilWardsToExtraBallot[lower]
  },
  authorisationLine: 'Authorised by Jonathan Parry, NT Greens, 15 Somerville Gardens, Parap NT 0820',
  whyVoteGreenContent: <TAS24WVG />,
  shareUrl: new URL('https://vote.greens.org.au'),
  shareText: "Hey, here's how to vote Greens this election.",
  footerLinks: [
    { title: 'Main Site', url: new URL('https://greens.org.au/nt') },
    { title: 'Privacy', url: new URL('https://greens.org.au/privacy') },
  ],
  showOverseasInfo: false,
  defaultLocation: {
    lat: -35.281641,
    lng: 149.13117
  },
}

export const ACT24: Config = {
  election: {
    code: 'actqld24',
    name: '2024 Australian Capital Territory Election',
    type: STATE,
    state: ACT,
    electionDay: new Date('2024-10-19'),
    pollsAreClosed: false,
  },
  ballot: ACT24Ballot,
  prodDomain: 'vote.greens.org.au',
  electionHasSausage: false,
  pollsAreClosed: false,
  partyNameOnBallot: ['The Greens', 'THE GREENS'],
  showGroupLabelOnBallot: true,
  getCandidateInfo: true,
  getCandidateInfoUpper: true,
  hideUpper: true,
  electorates: {
    lower: ACTStateDistricts,
    lowerType: DISTRICT,
    upper: [''],
    lowerToUpper: (lower) => QueenslandCouncilWardsToExtraBallot[lower]
  },
  authorisationLine: 'Authorised by James Cruz & Julie Randall, The ACT Greens, 2/18 Lonsdale St, Braddon ACT 2612',
  whyVoteGreenContent: <ACT24WVG />,
  shareUrl: new URL('https://act.vote.greens.org.au'),
  shareText: "Hey, here's how to vote Greens this election.",
  footerLinks: [
    { title: 'Main Site', url: new URL('https://greens.org.au/act') },
    { title: 'Privacy', url: new URL('https://greens.org.au/privacy') },
  ],
  showOverseasInfo: false,
  defaultLocation: {
    lat: -35.281641,
    lng: 149.13117
  },
}

export const QLD24: Config = {
  election: {
    code: 'actqld24',
    name: '2024 Queensland Election',
    type: STATE,
    state: QLD,
    electionDay: new Date('2024-10-26'),
    pollsAreClosed: false,
  },
  ballot: QLD24Ballot,
  prodDomain: 'vote.greens.org.au',
  electionHasSausage: false,
  pollsAreClosed: false,
  partyNameOnBallot: ['The Greens', 'THE GREENS'],
  showGroupLabelOnBallot: true,
  getCandidateInfo: true,
  getCandidateInfoUpper: true,
  hideUpper: true,
  electorates: {
    lower: QueenslandStateDivisions,
    lowerType: DISTRICT,
    upper: [''],
    lowerToUpper: (lower) => QueenslandCouncilWardsToExtraBallot[lower]
  },
  authorisationLine: 'Authorised by K. Carra, Queensland Greens, 47 Park Road, Milton 4064',
  whyVoteGreenContent: <QLD24WVG />,
  shareUrl: new URL('https://qld.vote.greens.org.au'),
  shareText: "Hey, here's how to vote Greens this election.",
  footerLinks: [
    { title: 'Main Site', url: new URL('https://greens.org.au/qld') },
    { title: 'Privacy', url: new URL('https://greens.org.au/privacy') },
  ],
  showOverseasInfo: false,
  defaultLocation: {
    lat: -27.4705,
    lng: 153.0260
  },
}

export const WA25: Config = {
  election: {
    code: 'wa25',
    name: '2025 WA State Election',
    electionDay: new Date('2025-03-08'),
    pollsAreClosed: false,
    type: STATE,
    state: WA,
  },
  ballot: WA25Ballot,
  pollsAreClosed: false,
  prodDomain: 'vote.greens.org.au',
  partyNameOnBallot: ['The Greens (WA)'],
  getCandidateInfo: false,
  showUpperFirst: true,
  headerTitle: 'Vote 1 Greens',
  showGroupLabelOnBallot: false,
  linkCandidateOnBallot: false,
  electorates: {
    lower: WaStateDistricts,
    lowerType: DISTRICT,
    upper: ['WA'],
    upperType: StateElectorate,
    lowerToUpper: (lower) => 'WA'
  },
  boldGreensCandidate: true,
  defaultLocation: {
    lat: -24.1448,
    lng: 111.8624
  },
  authorisationLine: 'Authorised by Chilla Bulbeck & Giz Watson, The Greens (WA), 91/215 Stirling St, Perth 6000',
  whyVoteGreenContent: <Wa25WVG />,
  shareUrl: new URL('https://vote.greens.org.au'),
  shareText: 'Vote [1] Greens for a future for all of us.',
  footerLinks: [
    { title: 'Privacy', url: new URL('https://greens.org.au/privacy') },
    { title: 'Main website', url: new URL('https://greens.org.au/wa') }
  ],
  electionHasSausage: false,
  hideHowToVoteInstructions: false,
};

export default {
  VIC18,
  NSW19,
  FED19,
  QLD20,
  QLD20S,
  WA21,
  SA22,
  FED22,
  VIC22,
  NSW23,
  BCC24,
  TAS24,
  NT24,
  ACT24,
  QLD24,
  WA25
};
