import React from 'react';
import { Container } from 'react-bootstrap';

import { PopGrid } from '@australiangreens/components';

// import ElectionIssues from '~/helpers/constants/ElectionIssues';
// <PopGrid tiles={ElectionIssues.wa21} rowSize={3} />

/**
 * Component for the 2025 WA state election "Why Vote Green" section.
 */
export const Wa25WVG = () => (
  <Container>
    <>
      <p>&nbsp;</p>
      <p>This election, we have a real opportunity to elect up to six Greens to the Legislative Council in WA and <strong>secure the balance of power</strong>. The major parties put profits before people, but <strong>the Greens are here to change that</strong>.
      </p>
      <p>In balance of power, the Greens will push the government to act now on <strong>climate change, fix the housing crisis, and ease the cost of living burden</strong>. We’ll make the billionaires and big corporations <strong>pay their fair share</strong> to fund the solutions we desperately need.</p>
      <p>&nbsp;</p>
      <p>If you want change, you have to vote for it.</p>
      <p>&nbsp;</p>
      <p>Learn more about our election policy platform: <a href="https://greens.org.au/wa/platform">greens.org.au/wa/platform</a>.</p>
    </>

  </Container>
);

export default Wa25WVG;
